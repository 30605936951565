<template>
  <Layout>
    <form @submit.prevent="submit">
      <div v-if="showTipoInscricao" class="md:grid md:grid-cols-3 md:gap-6 mb-6 sm:mb-0">
        <div class="md:col-span-1">
          <div class="form-heading border-none">
            <h6 class="text-left">Tipo de inscrição</h6>
            <p class="text-sm">
              Campos marcados com (<span class="text-red-700">*</span>) são de
              preenchimento obrigatório.
            </p>
          </div>
        </div>

        <div class="section-content md:col-span-2">
          <div class="form">
            <div class="body">
              <div :class="{ group: true, groupError: $v.typeSelected.$error }">
                <label class="label"
                  >Tipo <span class="text-red-700">*</span></label
                >

                <select
                  class="w-full sm:w-6/12"
                  v-model.trim="$v.typeSelected.$model"
                >
                  <option :value="[]" disabled selected>
                    Selecione um tipo
                  </option>

                  <option
                    v-for="(type, index) in typeList"
                    :key="index"
                    :value="type"
                  >
                    {{ type.nome }}
                  </option>
                </select>

                <p class="messageError" v-if="!$v.typeSelected.validSelected">
                  Tipo deve ser selecionado.
                </p>
              </div>

              <div v-if="useState" :class="{ group: true, groupError: $v.typeSelected.$error }">
                <label class="label"
                >UF <span class="text-red-700">*</span></label
                >

                <select
                  class="w-full sm:w-6/12"
                  v-model.trim="$v.stateSelected.$model"
                >
                  <option :value="[]" disabled selected>
                    Selecione uma UF
                  </option>

                  <option
                    v-for="(state, index) in listSTATES"
                    :key="index"
                    :value="state"
                  >
                    {{ state }}
                  </option>
                </select>

                <p class="messageError" v-if="!$v.stateSelected.validSelected">
                  UF deve ser selecionada.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>

      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="form-heading border-none">
            <h6 class="text-left">{{ labelResponsavelInscricao }}</h6>
            <p class="text-sm">
              Campos marcados com (<span class="text-red-700">*</span>) são de
              preenchimento obrigatório.
            </p>
          </div>
          <div v-if="LGPD_MESSAGE" v-html="LGPD_MESSAGE">

          </div>
        </div>

        <div class="section-content md:col-span-2">
          <div class="form">
            <div class="body">




              <div :class="{ group: true, groupError: $v.cpf.$error }">
                <label class="label" v-html="cpfLabel">
                </label>

                <input
                  type="text"
                  class="w-full sm:w-4/12"
                  :placeholder="cpfPlaceholder"
                  v-model.trim="$v.cpf.$model"
                />

                <p class="messageError" v-if="!$v.cpf.required">
                  {{cpfFieldName}} deve ser preenchido.
                </p>

                <p class="messageError" v-if="!$v.cpf.numeric">
                  {{cpfFieldName}} deve conter apenas números.
                </p>
              </div>

              <div :class="{ group: true, groupError: $v.fullname.$error }">
                <label class="label"
                  >Nome completo <span class="text-red-700">*</span></label
                >

                <input
                  type="text"
                  class="w-full sm:w-7/12"
                  placeholder="Nome completo"
                  v-model.trim="$v.fullname.$model"
                />

                <p class="messageError" v-if="!$v.fullname.required">
                  Nome completo deve ser preenchido.
                </p>

                <p class="messageError" v-if="!$v.fullname.minLength">
                  Nome completo deve ter pelo menos
                  {{ $v.fullname.$params.minLength.min }} letras.
                </p>
              </div>

              <div :class="{ group: true, groupError: $v.email.$error }">
                <label class="label">
                  E-mail <span class="text-red-700">*</span></label
                >

                <input
                  type="text"
                  class="w-full sm:w-6/12"
                  placeholder="E-mail"
                  v-model.trim="$v.email.$model"
                />

                <p class="messageError" v-if="!$v.email.required">
                  E-mail deve ser preenchido.
                </p>

                <p class="messageError" v-if="!$v.email.email">
                  E-mail informado deve ser válido.
                </p>
              </div>

              <div :class="{ group: true, groupError: $v.password.$error }">
                <label class="label">
                  Senha <span class="text-red-700">*</span></label
                >

                <input
                  type="password"
                  class="w-full sm:w-5/12"
                  placeholder="Senha"
                  v-model.trim="$v.password.$model"
                />

                <p class="messageError" v-if="!$v.password.required">
                  Senha deve ser preenchido.
                </p>

                <p class="messageError" v-if="!$v.password.validPassword">
                  Senha deve conter no minímo
                  {{ $v.password.$params.minLength.min }} caracteres, <br />
                  composta por letras maiúsculas, minúsculas e números.
                </p>

                <p
                  class="messageError"
                  v-if="!$v.password.minLength & $v.password.validPassword"
                >
                  Senha deve conter no minímo
                  {{ $v.password.$params.minLength.min }} caracteres.
                </p>
              </div>

              <div :class="{ group: true, groupError: $v.password2.$error }">
                <label class="label">
                  Confirmar Senha <span class="text-red-700">*</span></label
                >

                <input
                  type="password"
                  class="w-full sm:w-5/12"
                  placeholder="Senha"
                  v-model.trim="$v.password2.$model"
                />

                <p class="messageError" v-if="!$v.password2.required">
                  Confirmar sua senha.
                </p>
                <p class="messageError" v-if="!$v.password2.validPassword">
                  Senhas diferentes
                </p>
              </div>

              <div
                v-if="terms.length > 0"
                :class="{ group: true, groupError: $v.termo.$error }"
              >
                <label class="label">
                  Afirmo estar ciente que: <span class="text-red-700">*</span></label
                >
                <div class="block">
                  <div class="mt-2">
                    <div v-for="(term, index_term) in terms" :key="index_term">
                      <div class="inline-flex items-center">
                        <input
                          @click="atualizarTermo"
                          type="checkbox"
                          class="rounded"
                          v-model="term.value"
                        />
                        <span class="ml-2" v-html="term.label"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="messageError" v-if="!$v.termo.required">
                  Confirme a aceitação dos termos
                </p>
              </div>
            </div>
          </div>

          <div class="form-footer">
            <Button type="submit" color="white" background="lime-600">
              Registrar-se
            </Button>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  required,
  minLength,
  numeric,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import Layout from "@/components/layout/subscription/Layout.vue";
import Button from "@/components/elements/Button.vue";
import services from "@/services";

export default Vue.extend({
  name: "SubscriptionRegister",
  data() {
    return {
      LGPD_MESSAGE: '',
      listSTATES:  ['AC','AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO','MA', 'MG','MS','MT','PA','PB','PE','PI', 'PR', 'RJ','RN', 'RO','RR','RS','SC', 'SE','SP', 'TO'],
      useState: false,
      stateSelected: '',
      showTipoInscricao: false,
      terms: [],
      termo: "",
      typeList: [],
      typeSelected: [],
      fullname: "",
      cpf: "",
      email: "",
      password: "",
      password2: "",
      candidates: {},
    };
  },
  components: {
    Layout,
    Button,
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamSubscription", "getParamHotsite"]),
    labelResponsavelInscricao() {
      if (
        this.getParamHotsite.pages.subscription &&
        this.getParamHotsite.pages.subscription.register &&
        this.getParamHotsite.pages.subscription.register.labelResponsavelInscricao
      ) {
        return this.getParamHotsite.pages.subscription.register.labelResponsavelInscricao
      } else {
        return `Responsável pela inscrição`
      }
    },
    cpfLabel () {
      if (
        this.getParamHotsite.pages.subscription &&
        this.getParamHotsite.pages.subscription.register &&
        this.getParamHotsite.pages.subscription.register.labels &&
        this.getParamHotsite.pages.subscription.register.labels.cpf
      ) {
        return  this.getParamHotsite.pages.subscription.register.labels.cpf
      } else {
        return  "CPF ou Nº do Passaporte (se estrangeiro)<span class=\"text-red-700\">*</span>"
      }

    },
    cpfPlaceholder () {
      if (
        this.getParamHotsite.pages.subscription &&
        this.getParamHotsite.pages.subscription.register &&
        this.getParamHotsite.pages.subscription.register.placeholder &&
        this.getParamHotsite.pages.subscription.register.placeholder.cpf
      ) {
        return  this.getParamHotsite.pages.subscription.register.placeholder.cpf
      } else {
        return "Apenas números"
      }

    },
    cpfFieldName () {
      if (
        this.getParamHotsite.pages.subscription &&
        this.getParamHotsite.pages.subscription.register &&
        this.getParamHotsite.pages.subscription.register.fieldname &&
        this.getParamHotsite.pages.subscription.register.fieldname.cpf
      ) {
        return  this.getParamHotsite.pages.subscription.register.fieldname.cpf
      } else {
        return "CPF"
      }
    }

  },
  validations () {
    const result =  {
      stateSelected: {
        validSelected: (state) => (state !== '' || !this.useState),
      },
      typeSelected: {
        validSelected: (type) => type.nome !== undefined,
      },
      fullname: {
        required,
        minLength: minLength(4),
      },
      cpf: {
        required,
        numeric,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        validPassword: (password) => {
          return (
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password)
          )
        },
      },
      password2: {
        required,
        validPassword: sameAs('password'),
      },
    }
    if (this.terms.length > 0) {
      result.termo = {
        required,
      }
    }
    return result
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    initDataCandidates() {
      let candidates = [];

      for (let i = 0; i < this.typeSelected.diretoria.length; i++) {
        let values = {
          cargo_nome: this.typeSelected.diretoria[i],
          cargo_ordem: i + 1,
        };
        candidates.push(values);
      }

      for (let i = 0; i < candidates.length; i++) {
        let documents = [];

        this.typeSelected.files.forEach(() => {
          documents.push({ url: "" });
        });

        candidates[i].documentos = JSON.stringify(documents);
      }

      this.candidates = candidates;
    },
    async submit() {
      this.$v.$touch();
      this.atualizarTermo()
      if (!this.$v.$invalid) {
        this.changeLoading(true);

        this.initDataCandidates();

        let responsible = {
          type: "regional",
          typeRegional: {
            id: this.typeSelected.id,
            name: this.typeSelected.nome,
          },
          fullname: this.fullname,
          cpf: this.cpf,
          email: this.email,
          password: this.password,
          state: this.stateSelected
        };

        let candidates = this.candidates;
        if (!this.typeSelected.responsavel) {
          let candidate = candidates[0]
          candidate.nome_completo = responsible.fullname
          candidate.cpf = responsible.cpf
          candidate.email = responsible.email
          candidate.endereco_uf = responsible.state
        }
        let response = await services.Subscription.create({ responsible, candidates });
        this.changeLoading(false);
        if (response.data.status === "falhou") {
          this.sendNotification({
            type: "error",
            message: response.data.message,
            timeout: 8000,
          });
        } else {
          this.$router.push({ name: "SubscriptionLogin" });
          this.sendNotification({
            type: "success",
            message: response.data.message,
          });
        }

      }
    },
    atualizarTermo() {
      let count = 0
      for (let i = 0; i < this.terms.length; i++) {
        if (this.terms[i].value) {
          count++
        }
      }
      this.termo = count === this.terms.length ? "ok" : "";
    }
  },
  mounted() {
    this.typeList = this.getParamSubscription
    this.showTipoInscricao = this.typeList.length > 1
    if (!this.showTipoInscricao) {
      this.typeSelected = this.typeList[0]
    }
    let terms = this.getParamSubscription[0].terms || []
    this.terms = []
    for (const termsKey in terms) {
      this.terms.push({ label: terms[termsKey], value: false })
    }
    if (this.getParamHotsite.pages.subscription) {
      let page = this.getParamHotsite.pages.subscription
      if (page.LGPD_MESSAGE) {
        this.LGPD_MESSAGE = page.LGPD_MESSAGE
      }
      if (page.register) {
        this.useState = page.register.useState || false
      }

    }
    console.log("Subscription.Register");
  },
});
</script>
<style>
.termo_label {
  margin-left: 1em;
}
</style>
